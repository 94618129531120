* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    background: linear-gradient(rgb(0 0 0 / 60%) 0 0), url(./assets/photos/pexels-bence-csonka-7058511.jpg) no-repeat;
}

h1 {
    margin-top: 100px;
    text-align: center;
    font-size: 3.5rem;
    color: rgb(245, 172, 36);
    margin-bottom: 30px;
}

.form {
    display: flex;
    justify-content: center;

}

.btnDiv {
    display: flex;
    justify-content: center;
    margin: 50px 0;
}

.btn {
    width: 200px;
    font-size: 1.2rem;
    padding: 5px;
    border: 1px solid rgb(245, 172, 36);
    color: white;
    background: none;
    cursor: pointer;
    transition: background-color .5s;
    border-radius: 10px;
}

.btn:hover {
    background-color: rgb(245, 172, 36);
}

.btn:active {
    transform: scale(1.1);
}

::placeholder {
    color: whitesmoke;

}

.inputText {
    margin-top: 10px;
    width: 400px;
    
    outline: none;
    padding: 10px;
    text-align: center;
    color: whitesmoke;
     /* whitesmoke */
    background-color: rgba(240, 248, 255, 0);
    font-size: 1.4rem;
    border-bottom: 2px solid rgb(245, 172, 36);

}

.info {
    
    font-size: 1.5rem;
    font-weight: bold;
    color: whitesmoke;
    text-align: center;
}

.info p {
    margin-top: 22px;
}

.genelDeger {
    margin-top: 20px;
    display: flex;
    justify-content: center;
}

.genelDeger .fa-circle {
    font-size: 22px;
    transform: translate(10px, 25px);

}

.genelDeger .fa-c {
    transform: translate(10px, 25px);
    font-size: 5rem;
    color: rgb(245, 172, 36);
}

#sicaklik {

    font-size: 3.8rem;
}

.his {
    display: flex;
    justify-content: center;
}

.his .fa-c {
    font-size: 2rem;
    transform: translate(10px, 25px);
    color: rgb(245, 172, 36);
}

.his .fa-circle {
    font-size: 1rem;
    transform: translate(10px, 20px);
}

@media screen and (max-width: 600px) {
    h1 {
        margin-top: 50px;
        font-size: 2.5rem;
        margin-bottom: 50px;
    }
    .inputText {
        width: 300px;    
        padding: 5px;
  }

}